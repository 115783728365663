import React, { FC } from 'react';

import { Form, Formik } from 'formik';

import { useAuth } from 'containers/Auth/hooks/useAuth';
import { SharingUserPolicy } from 'containers/Sharing/Sharing.types';

import { useShareTeam } from '../hooks/useShareTeam';
import { useShareSettingsInitialValues } from '../ShareSelection/hooks/useShareSettingsInitialValues';
import { ShareSelectionOptionValue } from '../ShareSelection/ShareSelection.interface';

import { ShareFormSection } from './ShareFormSection';

interface Props {
  onChange?: (shares: ShareSelectionOptionValue[]) => void;
  sharedWith?: SharingUserPolicy[];
}

export const ShareForm: FC<Props> = ({ onChange, sharedWith }) => {
  const { me } = useAuth();
  const { data: teamMembers } = useShareTeam({
    enabled: !!me?.organization,
    me: me,
  });

  const initialValues = useShareSettingsInitialValues({
    organization: me?.organization ?? null,
    settings: {
      orgs: [],
      users: sharedWith ?? [],
    },
    teamMembers: teamMembers?.items ?? [],
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => void 0}
    >
      <Form>
        <ShareFormSection onChange={onChange} />
      </Form>
    </Formik>
  );
};
