import React, { FC, useCallback, useEffect, useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';
import ScrollList from 'common/components/List/ScrollList/ScrollList';

import { TextField } from '../../../TextField';
import ShareSelectedOption from '../ShareSelectedOption/ShareSelectedOption';

import { useShareSelectionOptions } from './hooks/useShareSelectionOptions';
import { ShareSelectionOptionValue } from './ShareSelection.interface';
import ShareSelectionNotifyAll from './ShareSelectionNotifyAll/ShareSelectionNotifyAll';
import ShareSelectionOptionContent from './ShareSelectionOptionContent/ShareSelectionOptionContent';

interface Props {
  accountName: string;
  fieldName: string;
  onChange?: (shares: ShareSelectionOptionValue[]) => void;
  organizationInitials: string;
  userRoleId: number;
  users: OrganizationMemberData[];
}

export const ShareSelection: FC<Props> = ({
  accountName,
  fieldName,
  onChange,
  organizationInitials,
  userRoleId,
  users,
}) => {
  const { t } = useTranslation('common');

  const [field, , fieldHelpers] =
    useField<ShareSelectionOptionValue[]>(fieldName);

  const clearSelectionText = t<string>(
    'share.shareSettings.userSelection.clearButton'
  );

  const options = useShareSelectionOptions({
    accountName,
    initials: organizationInitials,
    selected: field?.value,
    userRoleId,
    users,
  });

  const notifiedUsers = useMemo<ShareSelectionOptionValue[]>(
    () => field?.value?.filter(({ notify }) => notify),
    [field?.value]
  );

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = target;

      if (String(value) === String(userRoleId)) {
        void fieldHelpers.setValue([...options, ...(field?.value ?? [])]);
        return;
      }

      const selectedUser = options.find(({ id }) => id === value);

      if (selectedUser) {
        void fieldHelpers.setValue([selectedUser, ...(field?.value ?? [])]);
      }
    },
    [userRoleId, options, fieldHelpers, field?.value]
  );

  const handleNotifyAllChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      void fieldHelpers.setValue(
        field?.value.map((selectedUser) => ({
          ...selectedUser,
          notify: checked,
        }))
      );
    },
    [fieldHelpers, field?.value]
  );

  const handleClearSelection = () => {
    void fieldHelpers.setValue([]);
  };

  useEffect(() => {
    onChange?.(field?.value);
  }, [onChange, field?.value]);

  return (
    <>
      <TextField
        data-testid="ShareSelection-TextField"
        disabled={!options?.length}
        helperText={t('share.addUserGroupSelect.helperText')}
        inputProps={{ role: 'textbox' }}
        label={
          options?.length
            ? t('share.addUserGroupSelect.label')
            : t('share.addUserGroupSelect.allSelectedLabel')
        }
        SelectProps={{
          MenuProps: {
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
            style: { maxHeight: 400 },
            transformOrigin: { horizontal: 'center', vertical: 'top' },
          },
        }}
        value={''}
        fullWidth
        select
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <ShareSelectionOptionContent {...option} />
          </MenuItem>
        ))}
      </TextField>

      <FieldArray
        data-testid="ShareSelection-FieldArray"
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) =>
          field?.value?.length > 0 && (
            <>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <ShareSelectionNotifyAll
                  allUsersCount={field?.value?.length}
                  notifiedUsersCount={notifiedUsers?.length}
                  onChange={handleNotifyAllChange}
                />

                <Tooltip placement="left" title={clearSelectionText}>
                  <IconButton
                    aria-label={clearSelectionText}
                    size="small"
                    onClick={handleClearSelection}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <ScrollList>
                {field.value.map((value: ShareSelectionOptionValue, index) => {
                  const isUser = value.type === 'user';
                  const initials = value.initials ?? value.name?.[0] ?? '';
                  const secondary = isUser
                    ? value.email
                    : t('share.shareSettings.userSelection.options.team');

                  return (
                    <ShareSelectedOption
                      hideNotifyCheckbox={!isUser}
                      initials={initials}
                      key={value.id}
                      notifyFieldName={`${fieldName}[${index}].notify`}
                      permissionFieldName={`${fieldName}[${index}].permission`}
                      primary={value.name}
                      secondary={secondary}
                      onDelete={() => arrayHelpers.remove(index)}
                    />
                  );
                })}
              </ScrollList>
            </>
          )
        }
      />
    </>
  );
};

ShareSelection.displayName = 'ShareSelection';
