import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuth } from 'containers/Auth/hooks/useAuth';

import { FormSection } from '../../../FormSection/FormSection';
import { useShareTeam } from '../hooks/useShareTeam';
import { ShareSelection } from '../ShareSelection/ShareSelection';
import { ShareSelectionOptionValue } from '../ShareSelection/ShareSelection.interface';
import ShareSelectionSkeleton from '../ShareSelection/ShareSelectionSkeleton/ShareSelectionSkeleton';

interface Props {
  onChange?: (shares: ShareSelectionOptionValue[]) => void;
}

export const ShareFormSection: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('common');

  const { accountName, me, organizationInitials, userRoleId } = useAuth();

  const { data: teamMembers, isLoading } = useShareTeam({
    enabled: !!me?.organization,
    me,
  });

  return (
    <FormSection title={t('share.settings.usersOrgsAccessTitle')}>
      {isLoading && <ShareSelectionSkeleton />}
      {userRoleId && teamMembers && (
        <ShareSelection
          accountName={accountName}
          fieldName="selection"
          organizationInitials={organizationInitials}
          userRoleId={userRoleId}
          users={teamMembers.items}
          onChange={onChange}
        />
      )}
    </FormSection>
  );
};
