import React, { FC, useCallback, useState } from 'react';

import ShareIcon from '@mui/icons-material/Share';
import { Alert, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import type { SharingUserPolicy } from 'containers/Sharing/Sharing.types';

import { isFeatureEnabled } from '../../FeatureFlags/Feature';
import { ShareForm } from '../../Share/ShareSettings/ShareForm/ShareForm';
import {
  isShareUserOptionValue,
  ShareSelectionOptionValue,
} from '../../Share/ShareSettings/ShareSelection/ShareSelection.interface';

import NoteItemShareableTooltipTitle from './NoteItemShareableTooltipTitle';

interface Props {
  noteId: number;
  onSubmit: (users: SharingUserPolicy[]) => Promise<void>;
  shareable: boolean;
  sharedWith?: SharingUserPolicy[];
}

export const NoteItemShareableAction: FC<Props> = ({
  noteId,
  onSubmit,
  shareable,
  sharedWith,
}) => {
  const { t } = useTranslation('common');

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [shares, setShares] = useState<ShareSelectionOptionValue[]>([]);

  const handleSubmit = useCallback(async () => {
    const users = shares.filter(isShareUserOptionValue);
    await onSubmit(users);
    handleClose();
  }, [onSubmit, shares]);

  return (
    <>
      <Tooltip title={<NoteItemShareableTooltipTitle shareable={shareable} />}>
        <TrackedIconButton
          aria-label="share note"
          data-testid="share note"
          size="small"
          onClick={handleOpen}
        >
          <ShareIcon
            color={shareable ? 'secondary' : 'inherit'}
            fontSize="small"
          />
        </TrackedIconButton>
      </Tooltip>

      <ExtendedConfirmationDialog
        aria-label={t('notes.shareable.dialogTitle')}
        eventName={TrackEventName.SharedNoteMarked}
        eventProps={{ noteId: noteId }}
        maxWidth={isFeatureEnabled('ff-share-2') ? 'sm' : 'xs'}
        open={open}
        setOpen={setOpen}
        onSubmit={handleSubmit}
      >
        <Typography gutterBottom>
          {t('notes.shareable.confirmationTitle')}
        </Typography>

        <Alert severity="info" sx={{ mb: 2 }}>
          {t('notes.shareable.confirmationDescription')}
        </Alert>

        {isFeatureEnabled('ff-share-2') ? (
          <ShareForm sharedWith={sharedWith} onChange={setShares} />
        ) : null}
      </ExtendedConfirmationDialog>
    </>
  );
};
