import { SharingPermission } from 'containers/Sharing/Sharing.types';

export interface ShareOptionValue {
  notify: boolean;
  permission: SharingPermission;
  type: 'user' | 'organization';
}

export interface ShareOrgOptionValue extends ShareOptionValue {
  id: number;
  initials: string;
  name: string;
  type: 'organization';
}

export interface ShareUserOptionValue extends ShareOptionValue {
  email: string;
  id: string;
  initials: string;
  name: string;
  type: 'user';
}

export type ShareSelectionOptionValue =
  | ShareOrgOptionValue
  | ShareUserOptionValue;

export const isShareUserOptionValue = (
  share: ShareSelectionOptionValue
): share is ShareUserOptionValue => share.type === 'user';
