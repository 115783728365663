import React, { KeyboardEvent, useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { ColorPaletteField } from 'common/components/Fields/ColorPaletteField/ColorPaletteField';
import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import { FormSection } from 'common/components/FormSection/FormSection';
import { useShareTeam } from 'common/components/Share/ShareSettings/hooks/useShareTeam';
import { ShareSelection } from 'common/components/Share/ShareSettings/ShareSelection/ShareSelection';
import ShareSelectionSkeleton from 'common/components/Share/ShareSettings/ShareSelection/ShareSelectionSkeleton/ShareSelectionSkeleton';
import { TextField } from 'common/components/TextField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import TagShareLinkActions from 'containers/TagShare/TagShareLinkActions';

import TagDeleteButton from '../../TagDeleteButton/TagDeleteButton';
import { useTagSettingsForm } from '../hooks/useTagSettingsForm';
import { useTagSettingsInitialValues } from '../hooks/useTagSettingsInitialValues';
import { useTagSettingsValidation } from '../hooks/useTagSettingsValidation';
import { useTagsPalette } from '../hooks/useTagsPalette';

const useStyles = makeStyles((theme: Theme) => ({
  colorLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  switchLabel: {
    justifyContent: 'space-between',
    marginLeft: 0,
    width: '100%',
  },
}));

export type TagSettingsDialogProps = {
  setOpen: (state: boolean) => void;
  tag: TagDetails;
} & DialogProps;

const TagSettingsDialog = ({
  open,
  setOpen,
  tag,
  ...dialogProps
}: TagSettingsDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['tags', 'common']);
  const tagsPalette = useTagsPalette();
  const { accountName, me, organizationInitials, userRoleId } = useAuth();
  const organization = me?.organization ?? null;

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const { data: teamMembers, isLoading } = useShareTeam({
    enabled: !!organization && open,
    me,
  });

  const initialValues = useTagSettingsInitialValues({
    organization,
    tag,
    tagsPalette,
    teamMembers: teamMembers?.items ?? [],
  });

  const validationSchema = useTagSettingsValidation();

  const { onSubmit } = useTagSettingsForm({ onSubmitForm: handleClose, tag });

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const { isShareToPublicInTag, isShareToTeamInTag } = useTenantFeatures();

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={handleClose}
      {...dialogProps}
      aria-label={t('settings.dialog.ariaLabel')}
      data-testid="tagSettingsDialog"
    >
      <DialogActionTitle onClose={handleClose}>
        {t('tags:tagSettings')}
      </DialogActionTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ dirty, handleSubmit, isSubmitting, isValid, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
          >
            <DialogContent>
              <FormSection title={t('tags:settings.generalSettingsHeader')}>
                <Field name="tagName">
                  {({ field, meta }: FieldProps<string>) => (
                    <TextField
                      {...field}
                      error={!!(meta.error && meta.touched)}
                      helperText={meta.touched && meta.error}
                      inputProps={{ 'aria-label': 'Tag name input' }}
                      label={t('tags:settings.tagName.label')}
                      margin="normal"
                      autoFocus
                      fullWidth
                      multiline
                      onKeyPress={handleKeyPress}
                    />
                  )}
                </Field>
                <FormControlLabel
                  classes={{ root: classes.switchLabel }}
                  control={<FormSwitchControl name="enableRecommendations" />}
                  label={t('tags:settings.enableRecommendations.label')}
                  labelPlacement="start"
                />
                <FormControl margin="normal">
                  <FormLabel className={classes.colorLabel}>
                    {t('tags:settings.tagColor.subHeader')}
                  </FormLabel>
                  <Field name="color">
                    {({ field }: FieldProps<string>) => (
                      <ColorPaletteField
                        {...field}
                        colorPalette={tagsPalette}
                      />
                    )}
                  </Field>
                </FormControl>
              </FormSection>
              {isShareToPublicInTag && (
                <FormSection
                  title={t('common:share.settings.publicAccessTitle')}
                >
                  <FormControlLabel
                    control={
                      <FormSwitchControl name="shareSettings.isPublicTag" />
                    }
                    label={t('common:share.settings.isPublicTag.label')}
                    labelPlacement="start"
                    sx={{ width: '100%' }}
                  />
                  <FormHelperText>
                    {t('common:share.settings.isPublicTag.helperText')}
                  </FormHelperText>
                </FormSection>
              )}

              {isShareToTeamInTag && (
                <FormSection title={t('tags:shareSettings.subHeader')}>
                  {isLoading && <ShareSelectionSkeleton />}
                  {userRoleId && teamMembers && (
                    <ShareSelection
                      accountName={accountName}
                      fieldName="shareSettings.selection"
                      organizationInitials={organizationInitials}
                      userRoleId={userRoleId}
                      users={teamMembers.items}
                    />
                  )}
                  {(!!values.shareSettings.selection.length ||
                    values.shareSettings.isPublicTag) && (
                    <Box mt={2}>
                      <TagShareLinkActions tag={tag} />
                    </Box>
                  )}
                </FormSection>
              )}
            </DialogContent>
            <DialogActions>
              <Grid justifyContent="space-between" spacing={2} container>
                {tag && (
                  <Grid item>
                    <TagDeleteButton tag={tag} />
                  </Grid>
                )}
                <Grid justifyContent="flex-end" spacing={2} container item xs>
                  <Grid item>
                    <Button onClick={handleClose}>
                      {t('common:buttons.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <SubmitButton
                      disabled={!isValid || !dirty}
                      eventName={TrackEventName.TagSettingsUpdated}
                      eventProps={{ tagId: tag.id }}
                      isSubmitting={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

TagSettingsDialog.displayName = 'TagSettingsDialog';

export default TagSettingsDialog;
